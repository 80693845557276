<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      @queryByIdHandler="queryById"
      @deleteByIdHandler="deleteById"
      @batchDeleteHandler="batchDelete"
      @addHandler="saveTripDictionary"
      :loading="loading"
      :showDelete="false"
    >
      <template v-slot:search>
        <el-form-item label="代码类别:">
          <el-input
            v-model.trim="search.category"
            placeholder="请输入代码类别..."
          ></el-input>
        </el-form-item>

        <el-form-item label="代码CODE:">
          <el-input
            v-model.trim="search.code"
            placeholder="请输入代码code..."
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="category" label="代码类别"></el-table-column>
        <el-table-column prop="code" label="代码KEY"></el-table-column>
        <el-table-column prop="value" label="代码值"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="pid" label="pid"></el-table-column>
        <el-table-column prop="remark" label="备注信息"></el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
  
  <script>
import TableCompnent from "@/components/table/TablesComponents";
import TripDictionaryApi from "@/api/TripDictionaryApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
export default {
  name: "TripDictionaryList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      tableData: {},
      search: {
        category: "",
        code: "",
      },
      page: {},
      loading: true,
    };
  },
  methods: {
    reset() {
      this.search = this.$options.data().search;
      this.doSearch({pageSize:20,pageNum:1})
    },
    doSearch(params) {
      this.loading = true;
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      TripDictionaryApi.search(this.search).then((resp) => {
        if (resp.code === '200') {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    saveTripDictionary() {
      navTo(this, "/saveTripDictionary");
    },
    queryById(id) {
      TripDictionaryApi.queryTripDictionaryById(id).then((resp) => {
        if (resp.code === '200') {
          queryParam(this, "saveTripDictionary", resp.data);
        }
      });
    },
    deleteById(id) {
      TripDictionaryApi.deleteTripDictionary(id).then((resp) => {
        if (resp.code === '200') {
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    batchDelete(params) {
      TripDictionaryApi.batchDeleteTripDictionary(params).then((resp) => {
        if (resp.code === '200') {
          this.$successMsg(resp.msg);
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
  },
};
</script>
  <!--页面代码从这里结束拷贝-->